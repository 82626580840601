import styled from 'styled-components';

export const Page = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');

    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;

    div.header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 700px) {
        flex-direction: column;
      }

      div#groom {
        display: flex;

        h1#groom {
          color: #fff;
        }
      }

      img {
          max-width: 120px;
          max-height: 120px;
          margin-left: 20px;
          margin-right: 20px;

          @media (max-width: 700px) {
              max-width: 80px;
              max-height: 80px;
          }

          @media (max-width: 450px) {
              max-width: 60px;
              max-height: 60px;
          }
      }

      h1 {
          font-family: 'Raleway', sans-serif;
          font-size: 64px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: normal;
          line-height: 100%;
          color: #000;

          @media (max-width: 700px) {
              font-size: 48px;
          }

          @media (max-width: 450px) {
              font-size: 32px;
          }
      }


    }


    div.body {
        margin-top: 20px;
        margin-bottom: 50px;

        div#iframe {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #000;
          border: solid 3px #000;
          border-radius: 5px;
          padding: 2px;
          margin-bottom: 50px;
        }

        div.buttons {
          button {
            background: none;
            border: solid 3px #000;
            width: 260px;
            height: 66px;
            font-weight: bold;
            font-size: 16px;
            color: #000;
            margin-top: 20px;
            margin-left: calc(50% - 120px);
            transition: 0.5s;

            &:hover {
              background-color: #000;
              color: #fff;
            }
          }
        }
    }
`;
