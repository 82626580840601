import React, {  } from 'react';
import { Link } from 'react-router-dom';

import wreath from '../../assets/icons/laurel-wreath.png';

import {
    Page,
} from './styles';

const getIFrameDimensions = (width) => {
  if (width <=700 && width > 450) {
    return [360, 200];
  } else if (width <= 450) {
    return [310, 180]
  } else {
    return [560, 315];
  };
};

const { innerWidth: width } = window;

let iframeWidth  = getIFrameDimensions(width)[0];
let iframeHeight = getIFrameDimensions(width)[1];

function Marriage() {

  return (
    <Page>
      <div className="header">
        <h1>
          Raphaella
        </h1>
        <img id="wreath" src={wreath} alt="wreath" />
        <div id="groom">
          <h1>
            Matheus
          </h1>
          {iframeWidth > 700 &&  <h1 id="groom">
            ..
          </h1>}
        </div>
      </div>
      <div className="body">
          <div id="iframe">
            <iframe
              width={iframeWidth}
              height={iframeHeight}
              src="https://www.youtube.com/embed/MFlgug41EwU?controls=0&autoplay=1"
              title="Convite de Casamento"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="buttons">
            <Link to="/casamento/confirmacao">
              <button type="button">Confirmar Presença</button>
            </Link>
            <Link to="/casamento/presentes">
              <button type="button">Lista de Presentes</button>
            </Link>
          </div>
      </div>
    </Page>
  );
}

export default Marriage;
