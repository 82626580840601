import styled from 'styled-components';

export const Page = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');

    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;

    div.header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 30px;

      h1 {
        font-family: 'Raleway', sans-serif;
        font-size: 64px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        line-height: 100%;
        color: #000;
        text-align: center;

        @media (max-width: 700px) {
            font-size: 48px;
        }

        @media (max-width: 450px) {
            font-size: 32px;
        }
      }

      div.buttons {
        display: flex;
        align-items: center;
        width: 350px;
        justify-content: space-between;
        margin-top: 30px;

        button {
          background: none;
          border: solid 3px #000;
          width: 170px;
          height: 46px;
          font-weight: bold;
          font-size: 16px;
          color: #000;
          transition: 0.5s;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }

        button#back {
          background-color: #000;
          border: solid 3px #000;
          width: 170px;
          height: 46px;
          font-weight: bold;
          font-size: 16px;
          color: #fff;
          transition: 0.5s;

          &:hover {
            background-color: #fff;
            color: #222;
          }
        }
      }
    }


    div.body {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;

      @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }

      h1 {
          font-family: 'Raleway', sans-serif;
          font-size: 32px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: normal;
          color: #000;
          margin-bottom: 20px;
          text-align: center;

          @media (max-width: 700px) {
              font-size: 28px;
          }

          @media (max-width: 450px) {
              font-size: 22px;
          }
      }

      h2 {
            font-family: 'Raleway', sans-serif;
            font-size: 22px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            margin-bottom: 10px;

            @media (max-width: 700px) {
                font-size: 18px;
            }

            @media (max-width: 450px) {
                font-size: 16px;
            }
        }

        h3 {
            font-family: 'Raleway', sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            margin-bottom: 10px;

            @media (max-width: 700px) {
                font-size: 16px;
            }

            @media (max-width: 450px) {
                font-size: 14px;
            }
        }

        h4 {
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            margin-bottom: 10px;
            text-align: center;

            @media (max-width: 700px) {
                font-size: 14px;
            }

            @media (max-width: 450px) {
                font-size: 14px;
            }
        }

      div#credit {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50%;

        @media (max-width: 700px) {
          width: 100%;
        }

        button {
          background: none;
          border: solid 3px #000;
          width: 170px;
          height: 46px;
          font-weight: bold;
          font-size: 16px;
          color: #000;
          transition: 0.5s;
          margin-top: 20px;
          margin-bottom: 30px;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }
      }

      div#pix {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50%;

        @media (max-width: 700px) {
          width: 100%;
        }

        div.field {
          display: flex;
          width: 80%;
          align-items: flex-end;
          justify-content: space-between;

          @media (max-width: 800px) {
            width: 100%;
          }

          @media (max-width: 700px) {
            width: 80%;
          }

          @media (max-width: 450px) {
            width: 100%;
          }
        }

        img {
          max-width: 320px;
          max-height: 320px;
          margin-bottom: 40px;

          @media (max-width: 700px) {
              max-width: 220px;
              max-height: 220px;
          }

          @media (max-width: 450px) {
              max-width: 220px;
              max-height: 220px;
          }
        }
      }
    }
`;
