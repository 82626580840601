import styled from 'styled-components';

export const Page = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');

    background-color: #222;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;

    div.header {
        margin-top: 50px;

        img {
            max-width: 390px;
            max-height: 527px;

            @media (max-width: 700px) {
                max-width: 290px;
                max-height: 427px;
            }

            @media (max-width: 500px) {
                max-width: 260px;
                max-height: 397px;
            }
        }
    }


    div.body {
        margin-top: 20px;
        margin-bottom: 50px;

        h1 {
            font-family: 'Raleway', sans-serif;
            font-size: 72px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            line-height: 100%;
            color: #fff;

            @media (max-width: 700px) {
                font-size: 62px;
            }

            @media (max-width: 500px) {
                font-size: 58px;
            }
        }
        h4 {
            font-family: 'Raleway', sans-serif;
            font-size: 26px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: 150%;
            color: #fff;

            @media (max-width: 700px) {
                font-size: 24px;
            }

            @media (max-width: 500px) {
                font-size: 20px;
            }
        }
        p {
            margin-top: 80px;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: 150%;
            color: #fff;

            @media (max-width: 700px) {
                font-size: 14px;
            }

            @media (max-width: 500px) {
                font-size: 14px;
            }
        }
    }
`;

