import React from 'react';

import adsIfood from '../../assets/images/ifood.jpg';
import maps from '../../assets/images/maps.jpg';
import whatsapp from '../../assets/images/whattsapp.png';
import messenger from '../../assets/images/messenger.png';
import google from '../../assets/images/google.png';
import messengerMarket from '../../assets/images/messenger-market.jpg';
import ecommerce from '../../assets/images/ecommerce.png';
import mixRedeSocial from '../../assets/images/mix-redesSociais.png';
import money from '../../assets/images/investment.png';
import goal from '../../assets/images/goal.png';
import avatar from '../../assets/images/pink_avatar.jpg';

import {
  Page,
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  Footer,
} from './styles';

function Main() {
  return (
    <Page>
      <SectionOne>
        <div className="header">
          <h1>
            VENDA <br /> MUITO MAIS <br /> ATRAVÉS DA INTERNET
          </h1>
          <h2>
            FAÇA O DELIVERY DO <br /> SEU NEGÓCIO LOCAL BOMBAR!
          </h2>
        </div>

        <div className="ifood">
          <div className="text">
            <p className="white">
              Faça anúncios patrocinados nas redes sociais com padrão iFood e
              converta muito mais clientes.
            </p>
            <p className="purple">
              Use a seu favor o poder do Facebook, Instagram, Google e Youtube.
            </p>
          </div>
          <img id="ifood" src={adsIfood} alt="adsIfood" />
        </div>

        <div className="maps">
          <img id="maps" src={maps} alt="maps" />
          <div className="text">
            <p className="white">
              Anuncie apenas às pessoas próximas ao seu negócio, com interesses
              certos e em horários específicos.
            </p>
            <p className="purple">
              Atingindo somente as pessoas corretas, há um drástico aumento do
              custo/benefício.
            </p>
          </div>
        </div>

        <div className="whats">
          <div className="text">
            <p className="white">
              Leve as pessoas diretamente ao seu WhatsApp e os atenda de maneira
              próxima e personalizada.
            </p>
            <p className="purple">
              Automatize o fluxo de mensagens no atendimento para aumentar a
              produtividade.
            </p>
          </div>
          <div className="images">
            <img id="whatsapp" src={whatsapp} alt="whatsapp" />
            <img id="messenger" src={messenger} alt="messenger" />
          </div>
        </div>

        <div className="google">
          <div className="images">
            <img
              id="messenger-market"
              src={messengerMarket}
              alt="messenger-market"
            />
            <img id="google" src={google} alt="google" />
          </div>
          <div className="text">
            <p className="white">
              Coloque a sua empresa na primeira página do Google.
            </p>
            <p className="purple">
              Seja encontrado facilmente no GPS com o Google Meu Negócio.
            </p>
          </div>
        </div>

        <div className="ecommerce">
          <div className="text">
            <p className="white">
              Tenha um site para ecommerce personalizado para o seu negócio.
            </p>
            <p className="purple">
              Crie facilidades e melhore a experiência de compra so seu cliente.
            </p>
          </div>
          <img id="ecommerce" src={ecommerce} alt="ecommerce" />
        </div>

        <div className="money">
          <div className="images">
            <img id="mixRedeSocial" src={mixRedeSocial} alt="mixRedeSocial" />
            <h1>=</h1>
            <img id="money" src={money} alt="money" />
          </div>
          <div className="text">
            <p className="white">
              Aprenda a utilizar as redes socias de maneira profissional: saiba
              o que postar, como fazer stories, etc.
            </p>
            <p className="purple">
              Transforme a sua rede social em uma máquina de fazer dinheiro!
            </p>
          </div>
        </div>
        <a href="https://wa.me/5519997648347?text=Ol%C3%A1%2C%20Matheus!%20Quero%20saber%20melhor%20o%20que%20voc%C3%AA%20pode%20fazer%20pelo%20meu%20neg%C3%B3cio.">
          <button type="button">ENVIAR MENSAGEM</button>
        </a>
      </SectionOne>

      <div className="content">
        <SectionTwo>
          <div className="header">
            <h1>QUANTO CUSTA?</h1>
            <h2>
              PROPORCIONALMENTE <br /> MENOS DO QUE UMA PANFLETAGEM!
            </h2>
          </div>

          <div className="text01">
            <p className="white">
              Ao contrário do que muitos pensam, o marketing digital é bastante
              acessível.
            </p>
            <p className="purple">
              É possível começar investindo bem pouco, coisa de R$13,80 reais
              por dia, e já obter resultados.
            </p>
          </div>

          <div className="text02">
            <p className="white">
              Mas com uma diferença brutal na eficiência da entrega do anúncio.
            </p>
            <p className="purple">
              Como o meio digital permite uma segmentação muito fina, o seu
              anúncio aparecerá para uma pessoa que já tem grandes chances de
              possuir interesse no seu produto ou serviço.
            </p>
          </div>

          <div className="text03">
            <p className="white">
              Suponha que você tem um produto ideal para mulheres advogadas,
              recém casadas e que moram em bairros afastados do centro da
              cidade. Como atingir um perfil tão específico?
            </p>
            <p className="purple">
              Com o poder do Google e Facebook isso é possível. Tais empresas
              resguardam uma imensa quantidade de informação dos seus usuários
              que possibilita uma caracterização de perfil muito precisa.
            </p>
          </div>

          <div className="text04">
            <p className="white">
              Imagine tentar atingir tal perfil com os meios tradicionais de
              propaganda como outdoors ou panfletagem.
            </p>
            <p className="purple">
              Até chegar no alvo sua propaganda terá de passar por muita gente
              que não tem nada a ver. Isso é desperdício e desperdício é
              ineficiência que, por sua vez, é perda de dinheiro. Captou a
              ideia?
            </p>
          </div>

          <div className="text05">
            <p className="white">
              A magia do tráfego pago na internet é exatamente essa: o anúncio
              certo, para a pessoa correta e no momento exato.
            </p>
            <p className="purple">
              Já imaginou o anúncio da sua promoção de pizza passando na frente
              da pessoa no momento exato da fome? É juntar a fome com a vontade
              de comer!
            </p>
          </div>

          <h1 className="fly">NA MOSCA!</h1>
          <img id="goal" src={goal} alt="goal" />
          <a href="https://wa.me/5519997648347?text=Ol%C3%A1%2C%20Matheus!%20Quero%20saber%20melhor%20o%20que%20voc%C3%AA%20pode%20fazer%20pelo%20meu%20neg%C3%B3cio.">
            <button type="button">ENVIAR MENSAGEM</button>
          </a>
        </SectionTwo>
      </div>

      <SectionThree>
        <div className="header">
          <h1>COMO FUNCIONA?</h1>
          <h2>
            É SIMPLES, <br /> PESSOAL E JOGO RÁPIDO.
          </h2>
        </div>

        <div className="texts">
          <div className="text01">
            <h1>01</h1>
            <h2>COLETA</h2>
            <p>
              Conversaremos para <b>melhor entender o seu negócio:</b> o que
              você vende, quem são os seus principais clientes, qual o público
              alvo almejado, quais são as intenções com o marketing, aonde você
              quer chegar com o seu negócio e qual a pretensão de investimento
              em publicidade. Também definiremos as <b>suas necessidades:</b> já
              tem imagens ou precisa de um designer gráfico? Já tem página no
              Instagram? Já possui estrutura de delivery? É cadastrado no Google
              Meu Negócio? Já tem quem cuide das redes sociais? Precisa de um
              site para ecommerce? etc.
            </p>
          </div>

          <div className="text02">
            <h1>02</h1>
            <h2>PROPOSTA</h2>
            <p>
              Com as informações em mãos eu montarei algumas opções de{' '}
              <b>plano de marketing</b> completamente orientados à personalidade
              do seu negócio, das suas necessidades e, principalmente, que caiba
              dentro do seu orçamento. Esse plano será constituído de diversas
              frentes de ataque, desde a concepção da identidade do negócio até
              as estratégias de cupons de desconto, que poderão ser
              implementadas (ou não) de acordo com a sua disponibilidade e
              preferência.
            </p>
          </div>

          <div className="text03">
            <h1>03</h1>
            <h2>EXECUÇÃO</h2>
            <p>
              Mediante a <b>análise</b> a e <b>aceitação</b> do <b>plano</b>, o
              mesmo será posto em prática no instante que o o contrato for
              assinado e o pagamento acordado for compensado. O plano será
              reavaliado e alterado semanalmente de acordo com os relatórios de
              feedback e da sua satisfação como cliente.
            </p>
          </div>
        </div>
        <a href="https://wa.me/5519997648347?text=Ol%C3%A1%2C%20Matheus!%20Quero%20saber%20melhor%20o%20que%20voc%C3%AA%20pode%20fazer%20pelo%20meu%20neg%C3%B3cio.">
          <button type="button">ENVIAR MENSAGEM</button>
        </a>
      </SectionThree>
      <div className="content">
        <SectionFour>
          <div className="header">
            <h1>QUEM SOU EU?</h1>
          </div>

          <div className="profile">
            <img id="avatar" src={avatar} alt="Avatar" />
            <p>
              Meu nome é <b>Matheus Alves Arthus</b> e eu sou engenheiro,
              copywriter e programador profissional. Faço marketing em meio
              digital e gestão de tráfego profissional com{' '}
              <b>enfase em pequenos e médios negócios locais.</b> Residente na
              cidade de Rio Claro, interior de São Paulo, e de prontidão para
              ajudar o seu negócio <b>a vender mais através da internet.</b>
            </p>
          </div>

          <a href="https://wa.me/5519997648347?text=Ol%C3%A1%2C%20Matheus!%20Quero%20saber%20melhor%20o%20que%20voc%C3%AA%20pode%20fazer%20pelo%20meu%20neg%C3%B3cio.">
            <button type="button">ENVIAR MENSAGEM</button>
          </a>
        </SectionFour>
      </div>

      <SectionFive>
        <div className="header">
          <h1>COMO FAÇO?</h1>
          <h2>ME MANDE UMA MENSAGEM!</h2>
        </div>

        <div className="text">
          <p className="white">
            Para começarmos, basta me enviar uma mensagem por WhatsApp clicando
            no botão abaixo que eu mesmo lhe atenderei.
          </p>
          <p className="purple">Vamos trocar uma ideia sem compromisso!</p>
        </div>

        <a href="https://wa.me/5519997648347?text=Ol%C3%A1%2C%20Matheus!%20Quero%20saber%20melhor%20o%20que%20voc%C3%AA%20pode%20fazer%20pelo%20meu%20neg%C3%B3cio.">
          <button type="button">ENVIAR MENSAGEM</button>
        </a>
      </SectionFive>

      <Footer>
        <div>
          <h1>Sbk.</h1>
          <h2>SOBRE</h2>
          <p>
            marketing.sbuck@gmail.com <br />
            Tel: (19) 99764-8347 <br />
            Rio Claro - SP
          </p>
        </div>
      </Footer>
    </Page>
  );
}

export default Main;
