import styled from 'styled-components';

export const Page = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');

    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;

    div.header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 30px;

      h1 {
          font-family: 'Raleway', sans-serif;
          font-size: 64px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: normal;
          line-height: 100%;
          color: #000;
          text-align: center;

          @media (max-width: 700px) {
              font-size: 48px;
          }

          @media (max-width: 450px) {
              font-size: 32px;
          }
      }

      div.buttons {
        display: flex;
        align-items: center;
        width: 350px;
        justify-content: space-between;
        margin-top: 30px;

        button {
          background: none;
          border: solid 3px #000;
          width: 170px;
          height: 46px;
          font-weight: bold;
          font-size: 16px;
          color: #000;
          transition: 0.5s;

          &:hover {
            background-color: #000;
            color: #fff;
          }
        }

        button#back {
          background-color: #000;
          border: solid 3px #000;
          width: 170px;
          height: 46px;
          font-weight: bold;
          font-size: 16px;
          color: #fff;
          transition: 0.5s;

          &:hover {
            background-color: #fff;
            color: #222;
          }
        }
      }
    }


    div.body {
      width: 250px;

      h1 {
          font-family: 'Raleway', sans-serif;
          font-size: 32px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: normal;
          color: #000;
          margin-bottom: 20px;
          text-align: center;

          @media (max-width: 700px) {
              font-size: 28px;
          }

          @media (max-width: 450px) {
              font-size: 22px;
          }
      }

      h2 {
            font-family: 'Raleway', sans-serif;
            font-size: 22px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            margin-bottom: 10px;
            text-align: center;

            @media (max-width: 700px) {
                font-size: 18px;
            }

            @media (max-width: 450px) {
                font-size: 16px;
            }
        }

        h3 {
            font-family: 'Raleway', sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            margin-bottom: 10px;
            text-align: center;

            @media (max-width: 700px) {
                font-size: 16px;
            }

            @media (max-width: 450px) {
                font-size: 14px;
            }
        }

        h4 {
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000;
            margin-bottom: 10px;
            text-align: center;

            @media (max-width: 700px) {
                font-size: 14px;
            }

            @media (max-width: 450px) {
                font-size: 14px;
            }
        }

        button {
          background: none;
          border: solid 3px #00aa4e;
          width: 260px;
          height: 66px;
          font-weight: bold;
          font-size: 16px;
          color: #00aa4e;
          margin-top: 20px;
          margin-left: calc(50% - 120px);
          transition: 0.5s;

          &:hover {
            background-color: #00aa4e;
            color: #fff;
          }
        }
    }
`;
