import React from 'react';

import ebook from '../../assets/images/ebook-livro.png';

import {
    Page,
} from './styles';

function Thanks() {
return (
    <Page>
      <div className="header">
        <img id="ecommerce" src={ebook} alt="ebook" />
      </div>
      <div className="body">
          <h1>
            Muito obrigado.
          </h1>
          <h4>
            Você receberá o seu eBook através do e-mail cadastratado.
          </h4>
          <p>
            Não se esqueça de verificar na aba promoções e na caixa de spam. <br /> Qualquer problema, por favor, enviar e-mail para: aprendizagemextrema@gmail.com.
          </p>
      </div>
    </Page>
  );
}

export default Thanks;