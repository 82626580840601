import React from 'react';
import { Link } from 'react-router-dom';

import {
    Page,
} from './styles';


function Confirmation() {

  return (
    <Page>
      <div className="header">
        <h1>
          Confirmação de Presença
        </h1>
        <div className="buttons">
          <Link to="/casamento">
              <button id="back" type="button">Voltar</button>
          </Link>
          <Link to="/casamento/presentes">
            <button type="button">Lista de Presentes</button>
          </Link>
        </div>
      </div>
      <div className="body">
        <h4>
          Favor confirmar presença ou ausência até 22/07 com
        </h4>
        <h3>
          Ana Inforzato Cerimonial
        </h3>
        <h4>
          (dias úteis das 9h às 17h)
        </h4>
        <h3>
          Email: contato@anainforzato.com.br
        </h3>
        <h3>
          Fone: 19 3596-2880
        </h3>
        <h3>
          WhatsApp: 11 97612-5334
        </h3>
        <a href="https://api.whatsapp.com/send?phone=5511976125334&text=Ol%C3%A1%2C%20gostaria%20de%20confirmar%20presen%C3%A7a%20para%20o%20casamento%20da%20Raphaella%20e%20do%20Matheus.">
          <button type="button">ENVIAR MENSAGEM</button>
        </a>
      </div>
    </Page>
  );
}

export default Confirmation;
