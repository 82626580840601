import styled from 'styled-components';
import { lighten } from 'polished';

export const Page = styled.div`
  div.content {
    background-color: #5d89c9;
  }

  button {
    background: none;
    border: solid 3px #fff;
    width: 240px;
    height: 46px;
    font-weight: bold;
    color: #fff;
    margin-top: 50px;
    margin-left: calc(50% - 120px);
    transition: 0.5s;

    &:hover {
      border: solid 3px #000;
      color: #000;
    }
  }
`;

export const SectionOne = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

  max-width: 800px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  div.header {
    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 70px;
      font-weight: 700;
      text-shadow: 3px 3px 0 #5d89c7;
      color: #fff;

      @media (max-width: 700px) {
        font-size: 60px;
      }

      @media (max-width: 500px) {
        font-size: 50px;
      }
    }

    h2 {
      margin-top: 5px;
      font-family: 'Baloo 2', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;

      @media (max-width: 700px) {
        font-size: 24px;
      }

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }

  div.ifood {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -40px;
    flex: 1;

    @media (max-width: 700px) {
      margin-top: 10px;
      flex-direction: column;
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: -50px;
      margin-right: -20px;

      @media (max-width: 700px) {
        margin-top: 5px;
        align-items: flex-start;
      }

      p.white {
        max-width: 385px;
        background: #fff;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 4px 4px 4px 2px #5d89c7;
        position: relative;
        z-index: 1;

        @media (max-width: 700px) {
          max-width: 355px;
          margin-left: -10%;
        }

        @media (max-width: 500px) {
          max-width: 325px;
          margin-left: -5%;
        }
      }

      p.purple {
        max-width: 270px;
        background: #9716ab;
        padding: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-top: -10px;
        margin-right: -30px;

        @media (max-width: 700px) {
          max-width: 240px;
          margin-left: -5%;
        }

        @media (max-width: 500px) {
          max-width: 230px;
          margin-left: 0%;
        }
      }
    }

    img {
      max-width: 318px;
      max-height: 455px;
      border-radius: 4px;
      border: solid 1px #fff;
      left: 50%;

      @media (max-width: 700px) {
        max-width: 290px;
        max-height: 427px;
        margin-top: -27%;
        margin-left: 28%;
      }

      @media (max-width: 500px) {
        max-width: 260px;
        max-height: 397px;
        margin-top: -27%;
        margin-left: 25%;
      }
    }
  }

  div.maps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: -80px;
    flex: 1;

    @media (max-width: 700px) {
      margin-top: 10px;
      flex-direction: column;
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: -30px;

      @media (max-width: 700px) {
        align-items: flex-end;
        margin-left: 20%;
        margin-top: -10%;
      }

      @media (max-width: 500px) {
        align-items: flex-end;
        margin-left: 20%;
        margin-top: -15%;
      }

      p.white {
        max-width: 385px;
        background: #fff;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 4px 4px 4px 2px #5d89c7;
        position: relative;
        z-index: 1;

        @media (max-width: 700px) {
          max-width: 355px;
        }

        @media (max-width: 500px) {
          max-width: 345px;
        }
      }

      p.purple {
        max-width: 300px;
        background: #9716ab;
        padding: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-top: -10px;
        margin-left: -50px;

        @media (max-width: 700px) {
          margin-right: 20px;
          max-width: 270px;
        }

        @media (max-width: 500px) {
          margin-right: 20px;
          max-width: 240px;
        }
      }
    }

    img {
      max-width: 392px;
      max-height: 392px;
      border-radius: 4px;
      border: solid 1px #fff;

      @media (max-width: 700px) {
        max-width: 362px;
        max-height: 362px;
        margin-left: 2%;
      }

      @media (max-width: 500px) {
        max-width: 332px;
        max-height: 332px;
        margin-left: 0%;
      }
    }
  }

  div.whats {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    flex: 1;

    @media (max-width: 700px) {
      margin-top: 10px;
      flex-direction: column;
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: -30px;

      @media (max-width: 700px) {
        order: 1;
        margin-left: -15%;
        margin-top: -15%;
      }

      p.white {
        max-width: 385px;
        background: #fff;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 4px 4px 4px 2px #5d89c7;
        position: relative;
        z-index: 1;

        @media (max-width: 700px) {
          max-width: 355px;
        }

        @media (max-width: 500px) {
          max-width: 325px;
        }
      }

      p.purple {
        max-width: 300px;
        background: #9716ab;
        padding: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-top: -10px;
        margin-right: -50px;

        @media (max-width: 700px) {
          max-width: 270px;
          margin-right: -30px;
        }

        @media (max-width: 500px) {
          max-width: 240px;
          margin-top: -12px;
          margin-right: -15px;
        }
      }
    }

    div.images {
      img#whatsapp {
        max-width: 265px;
        max-height: 362px;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: solid 1px #fff;

        @media (max-width: 700px) {
          max-width: 225px;
          max-height: 322px;
          margin-left: 10px;
        }

        @media (max-width: 500px) {
          max-width: 185px;
          max-height: 282px;
          margin-left: 5px;
        }
      }

      img#messenger {
        max-width: 186px;
        max-height: 362px;
        border-radius: 20px;
        border: solid 1px #fff;
        margin-left: 10px;

        @media (max-width: 700px) {
          max-width: 156px;
          max-height: 332px;
        }

        @media (max-width: 500px) {
          max-width: 126px;
          max-height: 302px;
        }
      }

      @media (max-width: 700px) {
        order: 0;
      }
    }
  }

  div.google {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    flex: 1;

    @media (max-width: 700px) {
      margin-top: 10px;
      flex-direction: column;
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 80px;
      margin-left: -30px;

      @media (max-width: 700px) {
        margin-top: 0;
        align-items: flex-end;
        margin-left: 30%;
        margin-top: -20%;
      }

      p.white {
        max-width: 355px;
        background: #fff;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 4px 4px 4px 2px #5d89c7;
        position: relative;
        z-index: 1;

        @media (max-width: 700px) {
          max-width: 325px;
        }
      }

      p.purple {
        max-width: 300px;
        background: #9716ab;
        padding: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-top: -10px;
        margin-left: 20px;

        @media (max-width: 700px) {
          margin-right: 25px;
          max-width: 270px;
        }
      }
    }

    div.images {
      img#google {
        max-width: 265px;
        max-height: 362px;
        margin-left: 3px;

        @media (max-width: 700px) {
          max-width: 235px;
          max-height: 332px;
        }

        @media (max-width: 500px) {
          max-width: 195px;
          max-height: 292px;
        }
      }

      img#messenger-market {
        max-width: 186px;
        max-height: 362px;
        border-radius: 20px;
        border: solid 1px #fff;
        margin-left: 10px;

        @media (max-width: 700px) {
          max-width: 156px;
          max-height: 332px;
        }

        @media (max-width: 500px) {
          max-width: 136px;
          max-height: 312px;
          margin-left: 2px;
        }
      }
    }
  }

  div.ecommerce {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -80px;
    flex: 1;

    @media (max-width: 700px) {
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-end;
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: -30px;

      @media (max-width: 700px) {
        align-items: flex-start;
      }

      p.white {
        max-width: 350px;
        background: #fff;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 4px 4px 4px 2px #5d89c7;
        position: relative;
        z-index: 1;

        @media (max-width: 700px) {
          margin-left: -15%;
        }

        @media (max-width: 500px) {
          margin-left: -15%;
          max-width: 320px;
        }
      }

      p.purple {
        max-width: 270px;
        background: #9716ab;
        padding: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-top: -10px;
        margin-right: -50px;

        @media (max-width: 700px) {
          margin-left: -10%;
        }
      }
    }

    img#ecommerce {
      max-width: 222px;
      max-height: 480px;
      border-radius: 30px;
      border: solid 1px #fff;
      margin-left: 10px;

      @media (max-width: 700px) {
        margin-top: -20%;
        margin-right: 3%;
        max-width: 292px;
        max-height: 450px;
      }

      @media (max-width: 500px) {
        margin-top: -30%;
        margin-right: 3%;
        max-width: 262px;
        max-height: 420px;
      }
    }
  }

  div.money {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    flex: 1;

    div.images {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 700px) {
        margin-top: 10px;
        flex-direction: column;
      }

      h1 {
        font-family: 'Roboto', sans-serif;
        font-size: 100px;
        font-weight: 700;
        text-shadow: 3px 3px 0 #5d89c7;
        color: #fff;

        @media (max-width: 500px) {
          font-size: 80px;
        }
      }

      img#mixRedeSocial {
        max-width: 406px;
        max-height: 406px;

        @media (max-width: 700px) {
          max-width: 376px;
          max-height: 376px;
        }

        @media (max-width: 500px) {
          max-width: 346px;
          max-height: 346px;
        }
      }

      img#money {
        max-width: 160px;
        max-height: 165px;
        margin-left: 15px;

        @media (max-width: 700px) {
          max-width: 130px;
          max-height: 135px;
        }

        @media (max-width: 500px) {
          max-width: 100px;
          max-height: 105px;
        }
      }
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: -120px;
      margin-left: 50%;

      @media (max-width: 700px) {
        margin-top: 10px;
        margin-left: 0;
        align-items: center;
      }

      p.white {
        max-width: 355px;
        background: #fff;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 500;
        box-shadow: 4px 4px 4px 2px #5d89c7;
        position: relative;
        z-index: 1;

        @media (max-width: 500px) {
          max-width: 325px;
        }
      }

      p.purple {
        max-width: 300px;
        background: #9716ab;
        padding: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-top: -10px;

        @media (max-width: 700px) {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const SectionTwo = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

  max-width: 800px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;

  div.header {
    margin-bottom: 20px;
    align-items: flex-start;

    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 70px;
      font-weight: 700;
      text-shadow: 3px 3px 0 #e0288d;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 65px;
      }
    }

    h2 {
      margin-top: 5px;
      font-family: 'Baloo 2', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 0 10px;

    p.white {
      max-width: 680px;
      background: #fff;
      padding: 15px;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 500;
      box-shadow: 4px 4px 4px 2px #e0288d;
      position: relative;
      z-index: 1;
    }

    p.purple {
      max-width: 480px;
      background: #9716ab;
      padding: 10px;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      position: relative;
      z-index: 2;
      margin-top: -8px;

      @media (max-width: 700px) {
        max-width: 300px;
      }
    }
  }

  h1.fly {
    margin-top: 5px;
    align-self: center;
    font-family: 'Roboto', sans-serif;
    font-size: 70px;
    font-weight: 800;
    text-shadow: 4px 4px 0 #9716ab;
    color: ${lighten(0.2, '#5d89c7')};

    @media (max-width: 700px) {
      font-size: 50px;
    }

    @media (max-width: 500px) {
      font-size: 40px;
    }
  }

  img#goal {
    align-self: center;
    width: 246px;
    height: 246px;

    @media (max-width: 500px) {
      width: 216px;
      height: 216px;
    }
  }
`;

export const SectionThree = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

  max-width: 800px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  div.header {
    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 70px;
      font-weight: 700;
      text-shadow: 3px 3px 0 #5d89c7;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 60px;
      }
    }

    h2 {
      margin-top: 5px;
      font-family: 'Baloo 2', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 20px;
      }
    }
  }

  div.texts {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    div {
      width: 30%;

      @media (max-width: 700px) {
        width: 100%;
        padding: 0 30px;
      }

      h1 {
        font-family: 'Roboto', sans-serif;
        font-size: 200px;
        font-weight: 300;
        text-shadow: 4px 4px 0 #fff;
        color: #5d89c7;
        border-bottom: solid 3px #fff;

        @media (max-width: 700px) {
          align-self: center;
        }
      }

      h2 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 28px;
        font-weight: 400;
        line-height: 30px;
        color: #fff;
      }

      p {
        font-family: 'Roboto', sans-serif;
        font-size: 22px;
        font-weight: 300;
        line-height: 25px;
        color: #fff;
      }
    }
  }
`;

export const SectionFour = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

  max-width: 800px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;

  div.header {
    margin-bottom: 20px;
    align-items: flex-start;

    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 70px;
      font-weight: 700;
      text-shadow: 3px 3px 0 #e0288d;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 60px;
      }
    }
  }

  div.profile {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 700px) {
      padding: 0 25px;
    }

    img#avatar {
      width: 386px;
      height: 386px;
      border-radius: 50%;
      border: solid 3px #fff;

      @media (max-width: 700px) {
        width: 346px;
        height: 346px;
      }

      @media (max-width: 500px) {
        width: 316px;
        height: 316px;
      }
    }

    p {
      max-width: 490px;
      margin-top: 15px;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 300;
      line-height: 25px;
      color: #fff;
      text-align: justify;
    }
  }
`;

export const SectionFive = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

  max-width: 800px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  div.header {
    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 70px;
      font-weight: 700;
      text-shadow: 3px 3px 0 #5d89c7;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 65px;
      }
    }

    h2 {
      margin-top: 5px;
      font-family: 'Baloo 2', sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;

      @media (max-width: 500px) {
        font-size: 24px;
      }
    }
  }

  div.text {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    @media (max-width: 700px) {
      padding: 0 10px;
    }

    p.white {
      max-width: 680px;
      background: #fff;
      padding: 15px;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      font-weight: 500;
      box-shadow: 4px 4px 4px 2px #5d89c7;
      position: relative;
      z-index: 1;

      @media (max-width: 500px) {
        max-width: 640px;
      }
    }

    p.purple {
      max-width: 480px;
      background: #9716ab;
      padding: 10px;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      position: relative;
      z-index: 2;
      margin-top: -8px;

      @media (max-width: 700px) {
        max-width: 280px;
      }
    }
  }
`;

export const Footer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap');

  max-width: 800px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;

  div {
    margin-top: 20px;
    width: 20%;

    @media (max-width: 700px) {
      width: 40%;
    }

    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 42px;
      font-weight: 500;
      text-shadow: 3px 3px 0 #5d89c7;
      color: #fff;
      border-bottom: solid 3px #fff;
    }

    h2 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
      color: #fff;
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #fff;
    }
  }
`;
