import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Main from './pages/Main';
import Thanks from './pages/Thanks';
import Marriage from './pages/Marriage';
import Confirmation from './pages/Marriage/Confirmation';
import Gifts from './pages/Marriage/Gifts';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/marketing" exact component={Main} />
        <Route path="/obrigado-ebook" exact component={Thanks} />
        <Route path="/casamento" exact component={Marriage} />
        <Route path="/casamento/confirmacao" exact component={Confirmation} />
        <Route path="/casamento/presentes" exact component={Gifts} />
      </Switch>
    </BrowserRouter>
  );
}
