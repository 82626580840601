import React from 'react';
import { Link } from 'react-router-dom';

import qrcode from '../../../assets/images/qr-code-pix.png';

import {
    Page,
} from './styles';


function Gifts() {

  return (
    <Page>
      <div className="header">
        <h1>
          Lista de Presentes
        </h1>
        <div className="buttons">
          <Link to="/casamento">
              <button id="back" type="button">Voltar</button>
          </Link>
          <Link to="/casamento/confirmacao">
            <button type="button">Confirmar Presença</button>
          </Link>
        </div>
      </div>
      <div className="body">
        <div id="credit">
          <h1>
            Cartão de Crédito
          </h1>
          <a href="https://pag.ae/7YmUkgfM9">
            <button type="button">R$50</button>
          </a>
          <a href="https://pag.ae/7YmUk2Ezr">
            <button type="button">R$100</button>
          </a>
          <a href="https://pag.ae/7YmUjEZpr">
            <button type="button">R$200</button>
          </a>
          <a href="https://pag.ae/7Ysbv8G9w">
            <button type="button">R$300</button>
          </a>
          <a href="https://pag.ae/7YsbvA21b">
            <button type="button">R$500</button>
          </a>
          <a href="https://pag.ae/7Ysq4wyKv">
            <button type="button">R$700</button>
          </a>
          <a href="https://pag.ae/7Ysq4Rn4v">
            <button type="button">R$1000</button>
          </a>
          <h4>
            *Permite o parcelamento em até 12x.
          </h4>
          <h4>
            **Valores podem ser multiplicados no momento do pagamento (Ex: 3x R$50 = R$150).
          </h4>
        </div>
        <div id="pix">
          <h1>
            Pix
          </h1>
          <h2>
            QR Code:
          </h2>
          <img id="qrcode" src={qrcode} alt="qrcode" />
          <h2>
            Chave Pix:
          </h2>
          <div className="field">
            <h3>
              Chave:
            </h3>
            <h4>
              41623434890
            </h4>
          </div>
          <div className="field">
            <h3>
              Nome:
            </h3>
            <h4>
              Matheus Alves Arthus
            </h4>
          </div>
          <div className="field">
            <h3>
              Banco:
            </h3>
            <h4>
              260 - Nu Pagamentos S.A.
            </h4>
          </div>
          <div className="field">
            <h3>
              Identificador:
            </h3>
            <h4>
              PresentedeCasamento
            </h4>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Gifts;
